

class UrlConfig {
    ecrInitialDataURL (){
        return "http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/NCR/GetECRReportNumbers";
    }

    getEcrReportURL (){
        return "http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/ECR/GetECRReport";
    }
    
    createOrUpdateECRStage1URL (){
        return "http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/ECR/CreateOrUpdateECRStage1";
    }

    createOrUpdateECRStage2URL (){
        return "http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/ECR/CreateOrUpdateECRStage2";
    }
    createOrUpdateECRStage3URL (){
        return "http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/ECR/CreateOrUpdateECRStage3";
    }

    
    

} 

export default new UrlConfig();
