import React from "react";

const formsRoutes = [

  {
    path: "/forms/createOrder/:id",
    component: React.lazy(() => import("./PPC/CreateOrder"))
  },
  {
    path: "/forms/orderStatus",
    component: React.lazy(() => import("./PPC/OrderStatus"))
  },
  {
    path: "/forms/updateProducts/:orderId/:prodName",
    component: React.lazy(() => import("./PPC/SubProducts"))
  },
  {
    path: "/forms/orderDetailStatus",
    component: React.lazy(() => import("./PPC/OrderDetailStatus"))
  },
  {
    path: "/forms/productStatus/:id",
    component: React.lazy(() => import("./PPC/ProductStatus"))
  },
  {
    path: "/forms/createUser",
    component: React.lazy(() => import("./Admin/CreateUser"))
  },
  {
    path: "/forms/updateUser",
    component: React.lazy(() => import("./Admin/UpdateUser"))
  },
  {
    path: "/forms/raiseNCR",
    component: React.lazy(() => import("./NCR/RaiseNCR"))
  },
  {
    path: "/forms/Capa",
    component: React.lazy(() => import("./Capa/Capa"))
  },
  {
    path: "/forms/ECRProcess",
    component: React.lazy(() => import("./ECR/ECRProcess"))
  },
  {
    path: "/forms/PTABProcess",
    component: React.lazy(() => import("./PTAB/PTABProcess"))
  },
  {
    path: "/forms/dashboard1",
    component: React.lazy(() => import("./PPC/Dashboard1"))
  },
  {
    path: "/forms/dashboard2",
    component: React.lazy(() => import("./PPC/Dashboard2"))
  },
  {
    path: "/forms/dashboard3",
    component: React.lazy(() => import("./PPC/Dashboard3"))
  },
  {
    path: "/forms/cstComplaint",
    component: React.lazy(() => import("./CustomerComplaints/CstComplaints"))
  },
  {
    path: "/forms/raisePDO",
    component: React.lazy(() => import("./PDO/PDO"))
  },
  {
    path: "/forms/updateProductStatus",
    component: React.lazy(() => import("./PPC/UpdateProdStatus"))
  },
  {
    path: "/forms/itTicket",
    component: React.lazy(() => import("./Ticket/ITTicket"))
  }
];

export default formsRoutes;
