import {
  CAPA_CLOSURE_ERROR, 
  CAPA_CLOSURE_SUCCESS, 
  CAPA_CLOSURE_LOADING
} from "../../actions/CAPA/CapaClosureAction";

const initialState = {
  isSubmitLoading: false
};

const userReducer = function(state = initialState, action) {
  switch (action.type) {
    case CAPA_CLOSURE_LOADING: {
      return {
        ...state,
        isSubmitClosureLoading: true
      };
    }

    case CAPA_CLOSURE_SUCCESS: {
      return {
        ...state,
        isSubmitClosureLoading: false
      };
    }

    case CAPA_CLOSURE_ERROR: {
      return {
        ...state,
        isSubmitClosureLoading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default userReducer;
