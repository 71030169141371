export const navigations = [
  {
    name: "Quick Access",
    path: "/dashboard/quickaccess",
    icon: "dashboard"
  },
  {
    name: "PPC",
    icon: "add_location",
    children: [
      {
        name: "Dashboard 1",
        path: "/forms/dashboard1",
        iconText: "D1",
        features: "ContainerPlanner"
      },
      {
        name: "Dashboard 2",
        path: "/forms/dashboard2",
        iconText: "D2",
        features: "ValuePlanner"
      },
      {
        name: "Dashboard 3",
        path: "/forms/dashboard3",
        iconText: "D3",
        features: "QuantityPlanner"
      },
      {
        name: "Create New Work Order",
        path: "/forms/createOrder/:id",
        iconText: "CO",
        features: "CreateWorkOrder"
      },
      {
        name: "Work Order Status",
        path: "/forms/orderStatus",
        iconText: "O",
        features: "CreateWorkOrder"
      },
      {
        name: "Work Order Status",
        path: "/forms/updateProducts/:orderId/:prodName",
        iconText: "P",
        features: ""
      },
      {
        name: "All Work Order Status",
        path: "/forms/orderDetailStatus",
        iconText: "A",
        features: ""
      },
      {
        name: "Product Status",
        path: "/forms/productStatus/:id",
        iconText: "P",
        features: ""
      },
      {
        name: "Update Product Status",
        path: "/forms/updateProductStatus",
        iconText: "U",
        features: "CreateWorkOrder"
      }
    ]
  },
  {
    name: "Admin Control",
    icon: "settings",
    children: [
      {
        name: "Create User",
        path: "/forms/createUser",
        iconText: "CU",
        features: "CreateUser"
      },
      {
        name: "User Features",
        path: "/forms/updateUser",
        iconText: "U",
        features: "AddorUpdateFeature"
      }
    ]
  },
  {
    name: "NCR",
    icon: "list",
    path: "/forms/raiseNCR"
  },
  {
    name: "CAPA",
    icon: "list",
    path: "/forms/capa"
  },
  // {
  //   name: "Customer Complaint",
  //   icon: "assignment",
  //   path: "/forms/cstComplaint",
  // },
  {
    name: "ECR",
    icon: "list",
    path: "/forms/ECRProcess",
  },
  {
    name: "PDO",
    icon: "list",
    path: "/forms/raisePDO",
  },
  {
    name: "PTAB",
    icon: "list",
    path: "/forms/PTABProcess",
  },
  // {
  //   name: "IT Ticket",
  //   icon: "book",
  //   path: "#",
  //   children: []
  // },
  {
    name: "IT Ticket",
    icon: "assignment",
    path: "/forms/itTicket",
  }
];
