import { combineReducers } from "redux";
import LoginReducer from "./LoginReducer";
import UserReducer from "./Admin/UserReducer";
import LayoutReducer from "./LayoutReducer";
import ScrumBoardReducer from "./ScrumBoardReducer";
import NotificationReducer from "./NotificationReducer";
import EcommerceReducer from "./EcommerceReducer";
import NavigationReducer from "./NavigationReducer";

//NCR Reducer
import InitiateReducer from './NCR/InitiationReducer';
import Disposition from './NCR/DispositionReducer';
import Acceptance from './NCR/AcceptanceReducer';

//Admin
import DashboardReducer from './Dashboard/DashboardReducer';

//PPC
import PPCReducer from './PPC/PPCReducer';

//PPC -> Order
import OrderReducer from './PPC/Order/OrderReducer'; 

//PPC -> Product
import ProductReducer from './PPC/Products/ProductsReducer'; 


// //CAPA Reducer
// import CapaRootCause from './CAPA/RootCauseReducer';
// import CapaClosure from './CAPA/CapaClosureReducer';
// import CapaReport from './CAPA/CapaReportReducer';

//CAPA Reducer
import RootCauseReducer from './CAPA/RootCauseReducer';
import CapaClosureReducer from './CAPA/CapaClosureReducer';
import CapaReportReducer from './CAPA/CapaReportReducer';

//ECR Reducers
import ECRInitiatedReducer from './ECR/ECRInitiatedReducer';
import ECRCompletedReducer from  './ECR/ECRCompletedReducer';
import ECRQuantityReducer from './ECR/ECRQuantityReducer';
import PTABInitiateReducer from './PTAB/PTABInitiateReducer';
import PTABCompletedReducer from './PTAB/PTABCompletedReducer';

//PDO Reducer
import PDOStage1Reducer from './PDO/PDOStage1Reducer';
import PDOStage2Reducer from './PDO/PDOStage2Reducer';
import PDOStage3Reducer from './PDO/PDOStage3Reducer';
import PDOStage4Reducer from './PDO/PDOStage4Reducer';

//IT Ticket
import TicketReducer from './Ticket/ITTicket';

const RootReducer = combineReducers({
  login: LoginReducer,
  user: UserReducer,
  layout: LayoutReducer,
  scrumboard: ScrumBoardReducer,
  notification: NotificationReducer,
  ecommerce: EcommerceReducer,
  navigations: NavigationReducer,
  NCRinitiate: InitiateReducer,
  NCRDisposition: Disposition,
  NCRAcceptance: Acceptance,
  CapaRoot:RootCauseReducer,
  CapaReport:CapaReportReducer,
  CapaClosure:CapaClosureReducer,
  dashboard: DashboardReducer,
  ppc: PPCReducer,
  EcrInitiated:ECRInitiatedReducer,
  EcrCompleted:ECRCompletedReducer,
  EcrQuality:ECRQuantityReducer,
  PtabInitiate:PTABInitiateReducer,
  PtabCompleted:PTABCompletedReducer,
  PDOStage1: PDOStage1Reducer,
  PDOStage2: PDOStage2Reducer,
  PDOStage3: PDOStage3Reducer,
  PDOStage4: PDOStage4Reducer,
  OrderReducer: OrderReducer,
  ProductReducer: ProductReducer,
  TicketReducer: TicketReducer
});

export default RootReducer;
