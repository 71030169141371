import jwtAuthService from "../../services/jwtAuthService";
import { setUserData } from "./Admin/UserActions";
import { getNavigationByUser } from './NavigationAction';
import history from "history.js";

import { get, post } from './baseApi';
import { error, success } from '../../views/utilities/Toast/Toast';
import { authHeaderWithOutToken } from './AxiosHeader';
// import { sessionStorageService } from '../../services/sessionStorageService';
import AppSession from './Session';
import UrlConfig from './Admin/UrlConfig';

export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const RESET_PASSWORD = "RESET_PASSWORD";


  export const handleLogin = (payload) => async dispatch => {
    let url = UrlConfig.login();

    dispatch({
      type: LOGIN_LOADING
    });
    let header = authHeaderWithOutToken();
    let res = await post(url, payload, header);
    if(res && res.data){//res && res.data
      success('Sign in success...!');
      AppSession.setUser(res.data);
      sessionStorage.setItem("user_name",res.data.username);
      sessionStorage.setItem("user_id",res.data.id);
      sessionStorage.setItem("jwt_token",res.data.token);

      dispatch(setUserData(res.data));
      dispatch(getNavigationByUser(res.data));
      history.push({
        pathname: "/"
      });

      return dispatch({
        type: LOGIN_SUCCESS
      });
    }
    else if(res && res.isAxiosError){
      error('Invalid Username / password');
      return dispatch({
        type: LOGIN_ERROR,
        payload: res.isAxiosError
      });
    }
}

export function resetPassword({ email }) {
  return dispatch => {
    dispatch({
      payload: email,
      type: RESET_PASSWORD
    });
  };
}
