import {
    ECR_INITIATED_LOADING,
    ECR_INITIATED_SUCCESS,
    ECR_INITIATED_ERROR  ,
    GET_INITIAL_DATA_LOADING,
    GET_INITIAL_DATA_SUCCESS,
    GET_INITIAL_DATA_ERROR ,
    GET_ECR_REPORT_LOADING,
    GET_ECR_REPORT_SUCCESS,
    GET_ECR_REPORT_ERROR   ,
    
    } from "../../actions/ECR/ECRInitiatedAction";
    
    const initialState = {
      isSubmitLoading: false
    };
    
    const userReducer = function(state = initialState, action) {
    
      switch (action.type) {
        case ECR_INITIATED_LOADING: {
          return {
            ...state,
            isSubmitLoading: true
          };
        }
    
        case ECR_INITIATED_SUCCESS: {
          return {
            ...state,
            data:action.data,
            isSubmitLoading: false
          };
        }
    
        case ECR_INITIATED_ERROR: {
          return {
            ...state,
            isSubmitLoading: false
          };
        }
        case GET_INITIAL_DATA_LOADING: {
          return {
            ...state,
            loadingInitData: true
          };
        }
    
        case GET_INITIAL_DATA_SUCCESS: {
          return {
            ...state,
            ecrInitialData: action.data,
            loadingInitData: false
          };
        }
    
        case GET_INITIAL_DATA_ERROR: {
          return {
            ...state,
            loadingInitData: false
          };
        }

        case GET_ECR_REPORT_LOADING: {
          return {
            ...state,
            loadingReportData: true
          };
        }
    
        case GET_ECR_REPORT_SUCCESS: {
          return {
            ...state,
            ecrReport: action.data,
            loadingReportData: false
          };
        }
    
        case GET_ECR_REPORT_ERROR: {
          return {
            ...state,
            loadingReportData: false
          };
        }
        default: {
          return state;
        }
      }
    };
    
    export default userReducer;
    