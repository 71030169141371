// import history from "history.js";
import axios from "axios";
import { get, post, getWithParams } from "../baseApi";
import { error, success, warning } from "../../../views/utilities/Toast/Toast";
import { authHeaderWithToken, authHeaderWithOutToken } from "../AxiosHeader";
import UrlConfig from "./UrlConfig";

export const PDO_STAGE1_LOADING = "PDO_STAGE1_LOADING";
export const PDO_STAGE1_SUCCESS = "PDO_STAGE1_SUCCESS";
export const PDO_STAGE1_ERROR = "PDO_STAGE1_ERROR";


export const PDO_REPORT_LOADING = "PDO_REPORT_LOADING";
export const PDO_REPORT_SUCCESS = "PDO_REPORT_SUCCESS";
export const PDO_REPORT_ERROR = "PDO_REPORT_ERROR";
export const PDO_CLEAR_REPORT = "PDO_CLEAR_REPORT";

export const REF_LOADING = "REF_LOADING";
export const REF_SUCCESS = "REF_SUCCESS";
export const REF_ERROR = "REF_ERROR";

export const createOrUpdatePDOStage1 = (payload, isNew) => async (dispatch) => {
  let url = UrlConfig.CreateOrUpdatePDOStage1URL();

  dispatch({
    type: PDO_STAGE1_LOADING,
  });

  let header = authHeaderWithToken();
  let res = await post(url, payload, header);
  if (res && res.status === 200) {
    //res && res.data
    let message = `PDO ${isNew ? 'initiated' : 'updated'} successfully`;
    success(message);
    dispatch({
      type: PDO_STAGE1_SUCCESS,
      data: res.data,
    });
    return true;
  } else if (res && res.isAxiosError) {
    if (res.response && res.response.data && res.response.data.message) {
      error(res.response.data.message);
    } else {
      error("Error while initiating PDO...");
    }
    dispatch({
      type: PDO_STAGE1_ERROR,
      //payload: res.isAxiosError
    });
    return false;
  }
};

export const getPDORport = (id) => async (dispatch) => {
  let url = UrlConfig.getPDOReportURL();
  url = `${url}${id}`;

  dispatch({
    type: PDO_REPORT_LOADING,
  });

  let header = authHeaderWithToken();
  //let params = {reportid: id };
  
  let res = await get(url, header);
  if (res && res.status === 200) {
    //res && res.data

    return dispatch({
      type: PDO_REPORT_SUCCESS,
      data: res.data, //res.data
    });
  } 
  else if(res && res.status === 204){
    warning('Content not available.')
    return dispatch({
      type: PDO_REPORT_SUCCESS,
      data: res.data,
    });
  }
  else if (res && res.isAxiosError) {
    if (res.response && res.response.data && res.response.data.message) {
      error(res.response.data.message);
    } else {
      error("Error while fetching report...");
    }
    return dispatch({
      type: PDO_REPORT_ERROR,
      //payload: res.isAxiosError
    });
  }
}

export const getNCRPTABReferenceNumber = () => async (dispatch) => {
  let url = UrlConfig.getNCRPTABReferenceNumberURL();

  dispatch({
    type: REF_LOADING,
  });

  let header = authHeaderWithToken();
  //let params = {reportid: id };
  
  let res = await get(url, header);
  if (res && res.status === 200) {
    //res && res.data

    return dispatch({
      type: REF_SUCCESS,
      data: res.data, //res.data
    });
  } 
  else if(res && res.status === 204){
    warning('Content not available.')
    return dispatch({
      type: REF_SUCCESS,
      data: res.data,
    });
  }
  else if (res && res.isAxiosError) {
    if (res.response && res.response.data && res.response.data.message) {
      error(res.response.data.message);
    } else {
      error("Error while fetching Reference Number");
    }
    return dispatch({
      type: REF_ERROR,
      //payload: res.isAxiosError
    });
  }
}

export const clearStoredReport = () => dispatch =>{
  return dispatch({
    type: PDO_CLEAR_REPORT
  });
}
