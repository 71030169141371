import {
  PDO_STAGE2_LOADING, 
  PDO_STAGE2_SUCCESS, 
  PDO_STAGE2_ERROR
} from "../../actions/PDO/Stage2Action";

const initialState = {
  isPDOStage2SubmitLoading: false
};

const userReducer = function(state = initialState, action) {
  switch (action.type) {
    case PDO_STAGE2_LOADING: {
      return {
        ...state,
        isPDOStage2SubmitLoading: true
      };
    }

    case PDO_STAGE2_SUCCESS: {
      return {
        ...state,
        isPDOStage2SubmitLoading: false
      };
    }

    case PDO_STAGE2_ERROR: {
      return {
        ...state,
        isPDOStage2SubmitLoading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default userReducer;
