import axios from 'axios';
import { error } from "../../../views/utilities/Toast/Toast";
import { authHeaderWithToken } from "../AxiosHeader";
import UrlConfig from "./UrlConfig";

export const DASHBOARD_LOADING = "DASHBOARD_LOADING";
export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
export const DASHBOARD_ERROR = "DASHBOARD_ERROR";

export const getDashboardData = () => async (dispatch) => {
    let getDonutDataURL = UrlConfig.getDonutDataURL();
    let getLineChartDataURL = UrlConfig.getLineChartDataURL();
  
    dispatch({
      type: DASHBOARD_LOADING,
    });
  
    let header = authHeaderWithToken();
    let res = {};
  
    axios
      .all([
        axios.get(getDonutDataURL, { headers: header }),
        axios.get(getLineChartDataURL, { headers: header })
      ])
      .then((resp) => {
        console.log("donut: ", resp[0].data);
        console.log("line: ", resp[1].data);
        res = {
          ...res,
          donutData: resp[0].data,
          linechartData: resp[1].data,
        };
  
        dispatch({
          type: DASHBOARD_SUCCESS,
          data: res,
        });
      })
      .catch((err) => {
        error("Error while fetching data...");
        dispatch({
          type: DASHBOARD_ERROR,
        });
      });
  };
  