class UrlConfig {
    updateContainerPlannerURL (){
        return "http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/Dashboard/AddorUpdateContainerPlanner";
    }

    updateValuePlannerURL (){
        return "http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/Dashboard/AddorUpdateValuePlanner";
    }

    updateQTYPlannerURL(){
        return 'http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/Dashboard/AddorUpdateQuantityPlanner';
    }

    getFinancialYearURL(){
        return 'http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/Dashboard/GetFinancialYear';
    }

    getContDataURL(){
        return 'http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/Dashboard/GetContainerPlanner/';
    }

    getValueDataURL(){
        return 'http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/Dashboard/GetValuePlanner/';
    }

    getQTYDataURL(){
        return 'http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/Dashboard/GetQuantityPlanner/';
    }

    getCreateOrderURL(){
        return 'http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/WorkOrder/CreateWorkOrder';
    }

    getOrderURL(){
        return 'http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/WorkOrder/GetWorkOrders'
    }

    getProductURL(){
        return 'http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/WorkOrder/GetProducts/'
    }

    getCreateProductURL(){
        return 'http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/WorkOrder/CreateSubProducts/'
    }

    getSubProductURL(){
        return 'http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/WorkOrder/GetSubProducts/'
    }

    getSubUsers(){
        return 'http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/user/GetUsers/'
    }

    getAssignedSubProdURL(){
        return 'http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/WorkOrder/GetAssignedSubProducts/'
    }

    getAssignedProdURL(){
        return 'http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/WorkOrder/GetAssignedProducts/'
    }

    updateAssignedSubProductsURL(){
        return 'http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/WorkOrder/UpdateAssignedSubProducts'
    }

    updateAssignedProdURL(){
        return 'http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/WorkOrder/UpdateAssignedProducts'
    }
}

export default new UrlConfig();