import {
  DASHBOARD_LOADING,
  DASHBOARD_SUCCESS,
  DASHBOARD_ERROR
} from "../../actions/Dashboard/Dashboard";

const initialState = {
  dashboardLoading: false,
  data: null
};

const userReducer = function(state = initialState, action) {
  switch (action.type) {
    case DASHBOARD_LOADING: {
      return {
        ...state,
        dashboardLoading: true
      };
    }
    case DASHBOARD_SUCCESS: {
      return {
        ...state,
        data: action.data,
        dashboardLoading: false
      };
    }
    case DASHBOARD_ERROR: {
      return {
        ...state,
        dashboardLoading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default userReducer;
