import {
    PTAB_INITIATE_LOADING,
    PTAB_INITIATE_SUCCESS,
    PTAB_INITIATE_ERROR  ,

    GET_PTAB_REPORT_LOADING,
GET_PTAB_REPORT_SUCCESS,
GET_PTAB_REPORT_ERROR  
    
    } from "../../actions/PTAB/PTABInitiateAction";
    
    const initialState = {
      isSubmitLoading: false
    };
    
    const userReducer = function(state = initialState, action) {
    
      switch (action.type) {
        case PTAB_INITIATE_LOADING: {
          return {
            ...state,
            isSubmitLoading: true
          };
        }
    
        case PTAB_INITIATE_SUCCESS: {
          return {
            ...state,
            ptabReportId: action.data,
            isSubmitLoading: false
          };
        }
    
        case PTAB_INITIATE_ERROR: {
          return {
            ...state,
            isSubmitLoading: false
          };
        }
        case GET_PTAB_REPORT_LOADING: {
          return {
            ...state,
            loadingReportData: true
          };
        }
    
        case GET_PTAB_REPORT_SUCCESS: {
          return {
            ...state,
            ptabReport: action.data,
            loadingReportData: false
          };
        }
    
        case GET_PTAB_REPORT_ERROR: {
          return {
            ...state,
            loadingReportData: false
          };
        }
        default: {
          return state;
        }
      }
    };
    
    export default userReducer;
    