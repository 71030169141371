import {
    PDO_STAGE4_LOADING, 
    PDO_STAGE4_SUCCESS, 
    PDO_STAGE4_ERROR
  } from "../../actions/PDO/Stage4Action";
  
  const initialState = {
    isPDOStage4SubmitLoading: false
  };
  
  const userReducer = function(state = initialState, action) {
    switch (action.type) {
      case PDO_STAGE4_LOADING: {
        return {
          ...state,
          isPDOStage4SubmitLoading: true
        };
      }
  
      case PDO_STAGE4_SUCCESS: {
        return {
          ...state,
          isPDOStage4SubmitLoading: false
        };
      }
  
      case PDO_STAGE4_ERROR: {
        return {
          ...state,
          isPDOStage4SubmitLoading: false
        };
      }
  
      default: {
        return state;
      }
    }
  };
  
  export default userReducer;  