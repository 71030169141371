import history from "history.js";
import { get, post } from "../../baseApi";
import { error, success, warning } from "../../../../views/utilities/Toast/Toast";
import { authHeaderWithToken } from "../../AxiosHeader";
import UrlConfig from "../UrlConfig";

export const CREATE_ORDER_LOADING = "CREATE_ORDER_LOADING";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
export const CREATE_ORDER_ERROR = "CREATE_ORDER_ERROR";

export const UPDATE_ORDER_LOADING = "UPDATE_ORDER_LOADING";
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";
export const UPDATE_ORDER_ERROR = "UPDATE_ORDER_ERROR";

export const GET_ORDER_LOADING = "GET_ORDER_LOADING";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_ORDER_ERROR = "GET_ORDER_ERROR";

export const SELECTED_ORDER = "SELECTED_ORDER";
export const CLEAR_SELECTED_ORDER = "CLEAR_SELECTED_ORDER";


// GET_ORDER_LOADING
// GET_ORDER_SUCCESS
// GET_ORDER_ERROR

export const createOrder = (payload) => async (dispatch) => {
  let url = UrlConfig.getCreateOrderURL();
  dispatch({
    type: CREATE_ORDER_LOADING,
  });

  let header = authHeaderWithToken();
  let res = await post(url, payload, header);
  if (res && res.status === 200) {
    success("Order created successfully...");
    dispatch({
      type: CREATE_ORDER_SUCCESS
    });
    return true;

  } else if (res && res.isAxiosError) {
    if (res.response && res.response.data && res.response.data.message) {
      error(res.response.data.message);
    } else {
      error("Error while creating order");
    }
    dispatch({
      type: CREATE_ORDER_ERROR
    });
    return false;
  }
};


//------------get orders ---------------

export const getOrder = () => async (dispatch) => {
  let url = UrlConfig.getOrderURL();

  dispatch({
    type: GET_ORDER_LOADING,
  });

  let header = authHeaderWithToken();
  let res = await get(url, header);
  if (res && res.status === 200) {
    dispatch({
      type: GET_ORDER_SUCCESS,
      payload: res.data
    });
    return true;

  } else if (res && res.isAxiosError) {
    if (res.response && res.response.data && res.response.data.message) {
      error(res.response.data.message);
    } else {
      error("Error while getting Order");
    }
    dispatch({
      type: GET_ORDER_ERROR
    });
    return false;
  }
};


//------------------update order ------------

export const updateOrder = (payload) => async (dispatch) => {
  let url = UrlConfig.updateOrderURL();

  dispatch({
    type: UPDATE_ORDER_LOADING,
  });

  let header = authHeaderWithToken();
  let res = await post(url, payload, header);
  if (res && res.status === 200) {
    //res && res.data
    success("Order updated successfully...");

    dispatch({
      type: UPDATE_ORDER_SUCCESS,
    });
    return true;

  } else if (res && res.isAxiosError) {
    if (res.response && res.response.data && res.response.data.message) {
      error(res.response.data.message);
    } else {
      error("Error while updating Order");
    }
    dispatch({
      type: UPDATE_ORDER_ERROR
    });
    return false;
  }
};

export const storeSelectedOrderDetails = (orderDet) => dispatch =>{
  return dispatch({
    type: SELECTED_ORDER,
      payload: orderDet
  });
}

export const removeSelectedOrderDetails = () => dispatch => {
  return dispatch({
    type: CLEAR_SELECTED_ORDER
  });
}