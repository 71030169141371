class UrlConfig {

    getTicketTypeURL(){
        return 'http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/WorkOrder/GetTicketTypes'
    }

    submitTicketURL(){
        return 'http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/WorkOrder/CreateTicket'
    }

}

export default new UrlConfig();