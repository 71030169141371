class UrlConfig {
    
    CreateOrUpdatePDOStage1URL(){
        return 'http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/PDO/CreateOrUpdatePDOStage1'
    }

    CreateOrUpdatePDOStage2URL(){
        return 'http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/PDO/CreateOrUpdatePDOStage2'
    }

    CreateOrUpdatePDOStage3URL(){
        return 'http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/PDO/CreateOrUpdatePDOStage3'
    }

    CreateOrUpdatePDOStage4URL(){
        return 'http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/PDO/CreateOrUpdatePDOStage4'
    }

    getPDOReportURL(){
        return 'http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/PDO/GetPDOReport/'
    }

    getNCRPTABReferenceNumberURL(){
        return 'http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/NCR/GetPDOReportNumbers';
    }
}

export default new UrlConfig();