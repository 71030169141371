import axios from "axios";
import { get, post } from "../../baseApi";
import { error, success, warning } from "../../../../views/utilities/Toast/Toast";
import { authHeaderWithToken } from "../../AxiosHeader";
import UrlConfig from "../UrlConfig";

export const CREATE_PRODUCT_LOADING = "CREATE_PRODUCT_LOADING";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";
export const CREATE_PRODUCT_ERROR = "CREATE_PRODUCT_ERROR";

export const UPDATE_PRODUCT_LOADING = "UPDATE_PRODUCT_LOADING";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_ERROR = "UPDATE_PRODUCT_ERROR";

export const GET_PRODUCT_LOADING = "GET_PRODUCT_LOADING";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_ERROR = "GET_PRODUCT_ERROR";

export const GET_SUB_PRODUCT_LOADING = "GET_SUB_PRODUCT_LOADING";
export const GET_SUB_PRODUCT_ERROR = "GET_SUB_PRODUCT_ERROR";
export const GET_SUB_PRODUCT_SUCCESS = "GET_SUB_PRODUCT_SUCCESS";

export const CLEAR_SELECTED_PROD = "CLEAR_SELECTED_PROD";

export const GET_ASSIGNED_SUB_PRODUCT_LOADING = "GET_ASSIGNED_SUB_PRODUCT_LOADING";
export const GET_ASSIGNED_SUB_PRODUCT_SUCCESS = "GET_ASSIGNED_SUB_PRODUCT_SUCCESS";
export const GET_ASSIGNED_SUB_PRODUCT_ERROR = "GET_ASSIGNED_SUB_PRODUCT_ERROR";


export const GET_ASSIGNED_PRODUCT_SUCCESS = "GET_ASSIGNED_PRODUCT_SUCCESS";

export const UPDATE_ASSG_SUB_PRODUCT_LOADING = "UPDATE_ASSG_SUB_PRODUCT_LOADING";
export const UPDATE_ASSG_SUB_PRODUCT_SUCCESS = "UPDATE_ASSG_SUB_PRODUCT_SUCCESS";
export const UPDATE_ASSG_SUB_PRODUCT_ERROR = "UPDATE_ASSG_SUB_PRODUCT_ERROR";


export const UPDATE_ASSG_PRODUCT_LOADING = "UPDATE_ASSG_PRODUCT_LOADING";
export const UPDATE_ASSG_PRODUCT_SUCCESS = "UPDATE_ASSG_PRODUCT_SUCCESS";
export const UPDATE_ASSG_PRODUCT_ERROR = "UPDATE_ASSG_PRODUCT_ERROR";


// export const STORE_SELECTED_PROD = "STORE_SELECTED_PROD";
// export const CLEAR_SELECTED_PROD = "CLEAR_SELECTED_PROD";


export const createProducts = (payload) => async (dispatch) => {
  let url = UrlConfig.getCreateProductURL();
  dispatch({
    type: CREATE_PRODUCT_LOADING,
  });

  let header = authHeaderWithToken();
  let res = await post(url, payload, header);
  if (res && res.status === 200) {
    success("Sub Product created successfully...");
    dispatch({
      type: CREATE_PRODUCT_SUCCESS,
      data: res.data
    });
    return true;

  } else if (res && res.isAxiosError) {
    if (res.response && res.response.data && res.response.data.message) {
      error(res.response.data.message);
    } else {
      error("Error while creating Sub Products.");
    }
    dispatch({
      type: CREATE_PRODUCT_ERROR
    });
    return false;
  }
};


//------------get ProductList ---------------

export const getProductList = (orderId) => async (dispatch) => {
  let url = UrlConfig.getProductURL();
  url = `${url}${orderId}`;

  dispatch({
    type: GET_PRODUCT_LOADING,
  });

  let header = authHeaderWithToken();
  let res = await get(url, header);
  if (res && res.status === 200) {
    dispatch({
      type: GET_PRODUCT_SUCCESS,
      payload: res.data
    });
    return true;

  } else if (res && res.isAxiosError) {
    if (res.response && res.response.data && res.response.data.message) {
      error(res.response.data.message);
    } else {
      error("Error while getting Product");
    }
    dispatch({
      type: GET_PRODUCT_ERROR
    });
    return false;
  }
};

//------------------update order ------------

export const updateProducts = (payload) => async (dispatch) => {
  let url = UrlConfig.updateProductURL();

  dispatch({
    type: UPDATE_PRODUCT_LOADING,
  });

  let header = authHeaderWithToken();
  let res = await post(url, payload, header);
  if (res && res.status === 200) {
    //res && res.data
    success("Products updated successfully...");

    dispatch({
      type: UPDATE_PRODUCT_SUCCESS,
    });
    return true;

  } else if (res && res.isAxiosError) {
    if (res.response && res.response.data && res.response.data.message) {
      error(res.response.data.message);
    } else {
      error("Error while updating Product");
    }
    dispatch({
      type: UPDATE_PRODUCT_ERROR
    });
    return false;
  }
};

// export const getSubProducts = (id) => async (dispatch) => {
//   let url = UrlConfig.getSubProductURL();
//   url = `${url}:${id}`;
//   dispatch({
//     type: GET_SUB_PRODUCT_LOADING,
//   });

//   let header = authHeaderWithToken();


//   let res = await get(url, header);
//   if (res && res.status === 200) {

//     dispatch({
//       type: GET_SUB_PRODUCT_SUCCESS,
//       payload: res.data
//     });
//     return true;

//   } else if (res && res.isAxiosError) {
//     if (res.response && res.response.data && res.response.data.message) {
//       error(res.response.data.message);
//     } else {
//       error("Error while fetching sub Products.");
//     }
//     dispatch({
//       type: GET_SUB_PRODUCT_ERROR
//     });
//     return false;
//   }
// };

export const getSubProdInitialData = (groupId, orderId, prodId) => async (dispatch) => {
  let getSubProductURL = UrlConfig.getSubProductURL();
  getSubProductURL = `${getSubProductURL}${orderId}`

  let getSubUsers = UrlConfig.getSubUsers();
  getSubUsers = `${getSubUsers}${groupId}`;

  dispatch({
    type: GET_SUB_PRODUCT_LOADING,
  });

  let header = authHeaderWithToken();
  let res = {};
  let param = {
    params: {
      productId: prodId
    }
  }

  let config = {
    headers: header,
    params: {
      productId: prodId
    }
  }

  axios
    .all([
      axios.get(getSubProductURL, config),
      axios.get(getSubUsers, { headers: header })
    ])
    .then((resp) => {
      console.log("subProducts: ", resp[0].data);
      console.log("subUsers: ", resp[1].data);
      res = {
        ...res,
        subProducts: resp[0].data,
        subUsers: resp[1].data,
      };

      dispatch({
        type: GET_SUB_PRODUCT_SUCCESS,
        payload: res
      });
      return true;
    })
    .catch((err) => {
      error("Error while fetching data...");
      dispatch({
        type: GET_SUB_PRODUCT_ERROR
      });
      return false;
    });
};

export const removeProducts = () => dispatch => {
  return dispatch({
    type: CLEAR_SELECTED_PROD
  });
}

// export const storeSelectedProduct = (data) => dispatch =>{
//   return dispatch({
//     type: STORE_SELECTED_PROD,
//       payload: data
//   });
// }

// export const clearSelectedProduct = () => dispatch =>{
//   return dispatch({
//     type: CLEAR_SELECTED_PROD
//   });
// }


export const getAssignedSubProd = (userId) => async (dispatch) => {
  let url = UrlConfig.getAssignedSubProdURL();
  url = `${url}${userId}`;
  dispatch({
    type: GET_ASSIGNED_SUB_PRODUCT_LOADING,
  });

  let header = authHeaderWithToken();


  let res = await get(url, header);
  if (res && res.status === 200) {

    dispatch({
      type: GET_ASSIGNED_SUB_PRODUCT_SUCCESS,
      payload: res.data
    });
    return true;

  } else if (res && res.isAxiosError) {
    if (res.response && res.response.data && res.response.data.message) {
      error(res.response.data.message);
    } else {
      error("Error while fetching sub Products.");
    }
    dispatch({
      type: GET_ASSIGNED_SUB_PRODUCT_ERROR
    });
    return false;
  }
};

export const getAssignedProd = (userId) => async (dispatch) => {
  let url = UrlConfig.getAssignedProdURL();
  url = `${url}${userId}`;
  dispatch({
    type: GET_ASSIGNED_SUB_PRODUCT_LOADING,
  });

  let header = authHeaderWithToken();


  let res = await get(url, header);
  if (res && res.status === 200) {

    dispatch({
      type: GET_ASSIGNED_PRODUCT_SUCCESS,
      payload: res.data
    });
    return true;

  } else if (res && res.isAxiosError) {
    if (res.response && res.response.data && res.response.data.message) {
      error(res.response.data.message);
    } else {
      error("Error while fetching sub Products.");
    }
    dispatch({
      type: GET_ASSIGNED_SUB_PRODUCT_ERROR
    });
    return false;
  }
};


export const updateAssignedSubProducts = (payload) => async (dispatch) => {
  let url = UrlConfig.updateAssignedSubProductsURL();

  dispatch({
    type: UPDATE_ASSG_SUB_PRODUCT_LOADING,
  });

  let header = authHeaderWithToken();
  let res = await post(url, payload, header);
  if (res && res.status === 200) {
    //res && res.data
    success("Sub Products Quantity updated successfully...");

    dispatch({
      type: UPDATE_ASSG_SUB_PRODUCT_SUCCESS,
    });
    return true;

  } else if (res && res.isAxiosError) {
    if (res.response && res.response.data && res.response.data.message) {
      error(res.response.data.message);
    } else {
      error("Error while updating..");
    }
    dispatch({
      type: UPDATE_ASSG_SUB_PRODUCT_ERROR
    });
    return false;
  }
};


export const updateAssignedProd = (payload) => async (dispatch) => {
  let url = UrlConfig.updateAssignedProdURL();

  dispatch({
    type: UPDATE_ASSG_PRODUCT_LOADING,
  });

  let header = authHeaderWithToken();
  let res = await post(url, payload, header);
  if (res && res.status === 200) {
    //res && res.data
    success("Products Quantity updated successfully...");

    dispatch({
      type: UPDATE_ASSG_PRODUCT_SUCCESS,
    });
    return true;

  } else if (res && res.isAxiosError) {
    if (res.response && res.response.data && res.response.data.message) {
      error(res.response.data.message);
    } else {
      error("Error while updating..");
    }
    dispatch({
      type: UPDATE_ASSG_PRODUCT_ERROR
    });
    return false;
  }
};