

class UrlConfig {
    ptabInitiateURL (){
        return "http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/PTAB/CreateOrUpdatePTABStage1";
    }
    ptabCompletedURL (){
        return "http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/PTAB/CreateOrUpdatePTABStage2";
    }
    getPtabReportURL (){
        return "http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/PTAB/GetPTABReport";
    }

    
    

} 

export default new UrlConfig();
