// import history from "history.js";
import axios from "axios";
import { get, post } from "../baseApi";
import { error, success } from "../../../views/utilities/Toast/Toast";
import { authHeaderWithToken, authHeaderWithOutToken } from "../AxiosHeader";
import UrlConfig from "./UrlConfig";

export const CAPA_CLOSURE_LOADING = "CAPA_CLOSURE_LOADING";
export const CAPA_CLOSURE_SUCCESS = "CAPA_CLOSURE_SUCCESS";
export const CAPA_CLOSURE_ERROR   = "CAPA_CLOSURE_ERROR";

export const CreateOrUpdateCAPACorrectiveActionClosure = (payload) => async (dispatch) => {
  let url = UrlConfig.createOrUpdateCAPACorrectiveActionClosure();

  dispatch({
    type: CAPA_CLOSURE_LOADING
  });

  let header = authHeaderWithToken();
  let res = await post(url, payload, header);
  if (res && res.status === 200) {
    //res && res.data
    success("Capa Closure updated successfully...");
    dispatch({
      type: CAPA_CLOSURE_SUCCESS,
      //data: user//res.data
    });
    return true;
  } else if (res && res.isAxiosError) {
    if (res.response && res.response.data && res.response.data.message) {
      error(res.response.data.message);
    } else {
      error("Error while Submitting Capa Closure...");
    }
    dispatch({
      type: CAPA_CLOSURE_ERROR
      //payload: res.isAxiosError
    });
    return false;
  }
};

export const CreateOrUpdateCAPAPreventiveActionClosure = (payload) => async (dispatch) => {
  let url = UrlConfig.createOrUpdateCAPAPreventiveActionClosure();

  dispatch({
    type: CAPA_CLOSURE_LOADING
  });

  let header = authHeaderWithToken();
  let res = await post(url, payload, header);
  if (res && res.status === 200) {
    //res && res.data
    success("Capa Closure updated successfully...");
    dispatch({
      type: CAPA_CLOSURE_SUCCESS,
      //data: user//res.data
    });
    return true;
  } else if (res && res.isAxiosError) {
    if (res.response && res.response.data && res.response.data.message) {
      error(res.response.data.message);
    } else {
      error("Error while Submitting Capa Closure...");
    }
    dispatch({
      type: CAPA_CLOSURE_ERROR
      //payload: res.isAxiosError
    });
    return false;
  }
};
