import {
  GET_INIT_LOADING,
  GET_INIT_SUCCESS,
  GET_INIT_ERROR,
  CREATE_CONTAINER_LOADING,
  CREATE_CONTAINER_SUCCESS,
  CREATE_CONTAINER_ERROR,
  CREATE_VALUE_LOADING,
  CREATE_VALUE_SUCCESS,
  CREATE_VALUE_ERROR,
  CREATE_QTY_LOADING,
  CREATE_QTY_SUCCESS,
  CREATE_QTY_ERROR,
  
  GET_CONT_LOADING,
  GET_CONT_SUCCESS,
  GET_CONT_ERROR,
  CONT_CLEAR,
  
  
  GET_VALUE_LOADING,
  GET_VALUE_SUCCESS,
  GET_VALUE_ERROR,
  VALUE_CLEAR,
  
  
  GET_QTY_LOADING,
  GET_QTY_SUCCESS,
  GET_QTY_ERROR,
  QTY_CLEAR
  } from "../../actions/PPC/DashboardAction";
  
  const initialState = {
    initLoading: false,
    initData: null,
    contLoading: false,
    contData: null,
    getContLoading: false,
    valueLoading: false,
    valData: null,
    getValueLoading: false,
    qtyLoading: false,
    qtyData: null,
    getQTYLoading: false
  };
  
  const userReducer = function(state = initialState, action) {
    switch (action.type) {
      case GET_INIT_LOADING: {
        return {
          ...state,
          initLoading: true
        };
      }
      case GET_INIT_SUCCESS: {
        return {
          ...state,
          initData: action.data,
          initLoading: false
        };
      }
      case GET_INIT_ERROR: {
        return {
          ...state,
          initLoading: false
        };
      }
  
      case CREATE_CONTAINER_LOADING: {
        return {
          ...state,
          contLoading: true
        };
      }
      case CREATE_CONTAINER_SUCCESS: {
        return {
          ...state,
          contData: action.data,
          contLoading: false
        };
      }
      case CREATE_CONTAINER_ERROR: {
        return {
          ...state,
          contLoading: false
        };
      }
  
      case GET_CONT_LOADING: {
        return {
          ...state,
          getContLoading: true
        };
      }
      case GET_CONT_SUCCESS: {
        return {
          ...state,
          contData: action.data,
          getContLoading: false
        };
      }
      case GET_CONT_ERROR: {
        return {
          ...state,
          getContLoading: false
        };
      }
  
      case CONT_CLEAR: {
        return{
          ...state,
          contData: null,
        }
      }
  
      case CREATE_VALUE_LOADING: {
        return {
          ...state,
          valueLoading: true
        };
      }
      case CREATE_VALUE_SUCCESS: {
        return {
          ...state,
          // valData: action.data,
          valueLoading: false
        };
      }
      case CREATE_VALUE_ERROR: {
        return {
          ...state,
          valueLoading: false
        };
      }
  
      case GET_VALUE_LOADING: {
        return {
          ...state,
          getValueLoading: true
        };
      }
      case GET_VALUE_SUCCESS: {
        return {
          ...state,
          valData: action.data,
          getValueLoading: false
        };
      }
      case GET_VALUE_ERROR: {
        return {
          ...state,
          getValueLoading: false
        };
      }
  
      case VALUE_CLEAR: {
        return{
          ...state,
          valData: null,
        }
      }
  
      //-----------------qty------------
      case CREATE_QTY_LOADING: {
        return {
          ...state,
          qtyLoading: true
        };
      }
      case CREATE_QTY_SUCCESS: {
        return {
          ...state,
          qtyData: action.data,
          qtyLoading: false
        };
      }
      case CREATE_QTY_ERROR: {
        return {
          ...state,
          qtyLoading: false
        };
      }
  
  
  
      case GET_QTY_LOADING: {
        return {
          ...state,
          getQTYLoading: true
        };
      }
      case GET_QTY_SUCCESS: {
        return {
          ...state,
          qtyData: action.data,
          getQTYLoading: false
        };
      }
      case GET_QTY_ERROR: {
        return {
          ...state,
          getQTYLoading: false
        };
      }
  
      case QTY_CLEAR: {
        return{
          ...state,
          qtyData: null,
        }
      }
  
      default: {
        return state;
      }
    }
  };
  
  export default userReducer;