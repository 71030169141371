// import history from "history.js";
import axios from "axios";
import { get, post } from "../baseApi";
import { error, success } from "../../../views/utilities/Toast/Toast";
import { authHeaderWithToken, authHeaderWithOutToken } from "../AxiosHeader";
import UrlConfig from "./UrlConfig";

export const NCR_ACCEP_LOADING = "NCR_DISP_LOADING";
export const NCR_ACCEP_SUCCESS = "NCR_DISP_SUCCESS";
export const NCR_ACCEP_ERROR = "NCR_DISP_ERROR";

export const createOrUpdateNCRAcceptance = (payload) => async (dispatch) => {
  let url = UrlConfig.createOrUpdateNCRAcceptanceURL();

  dispatch({
    type: NCR_ACCEP_LOADING
  });

  let header = authHeaderWithToken();
  let res = await post(url, payload, header);
  if (res && res.status === 200) {
    //res && res.data
    success("NCR updated successfully...");
    dispatch({
      type: NCR_ACCEP_SUCCESS,
      //data: user//res.data
    });
    return true;
  } else if (res && res.isAxiosError) {
    if (res.response && res.response.data && res.response.data.message) {
      error(res.response.data.message);
    } else {
      error("Error while Submitting NCR...");
    }
    dispatch({
      type: NCR_ACCEP_ERROR
      //payload: res.isAxiosError
    });
    return false;
  }
};
