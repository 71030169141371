// import history from "history.js";
import axios from "axios";
import { get, post, getWithParams } from "../baseApi";
import { error, success, warning } from "../../../views/utilities/Toast/Toast";
import { authHeaderWithToken, authHeaderWithOutToken } from "../AxiosHeader";
import UrlConfig from "./UrlConfig";

export const ECR_INITIATED_LOADING = "ECR_INITIATED_LOADING"; 
export const ECR_INITIATED_SUCCESS = "ECR_INITIATED_SUCCESS";
export const ECR_INITIATED_ERROR  =   "ECR_INITIATED_ERROR";

export const GET_INITIAL_DATA_LOADING = "GET_INITIAL_DATA_LOADING"; 
export const GET_INITIAL_DATA_SUCCESS = "GET_INITIAL_DATA_SUCCESS";
export const GET_INITIAL_DATA_ERROR  =   "GET_INITIAL_DATA_ERROR"; 

export const GET_ECR_REPORT_LOADING = "GET_ECR_REPORT_LOADING";
export const GET_ECR_REPORT_SUCCESS = "GET_ECR_REPORT_SUCCESS";
export const GET_ECR_REPORT_ERROR  =   "GET_ECR_REPORT_ERROR"; 


export const getInitialData = (id) => async (dispatch) => {
  let ecrSerialNumbers = UrlConfig.ecrInitialDataURL();

  dispatch({
    type: GET_INITIAL_DATA_LOADING,
  });

  let header = authHeaderWithToken();
  let res = {};
  axios
    .all([
      axios.get(ecrSerialNumbers, { headers: header }),

    ])
    .then((resp) => {
      console.log("ecrSerialNumber: ", resp[0].data);
      
      res = {
        ...res,
        ecrSerialNumber: resp[0].data
        
      };
      dispatch({
        type: GET_INITIAL_DATA_SUCCESS,
        data: res,
      });
    })
    .catch((err) => {
      console.error("Error from POST call of ");
      dispatch({
        type: GET_INITIAL_DATA_ERROR,
      });
    });
};

export const getECRRport = (id) => async (dispatch) => {
  let url = UrlConfig.getEcrReportURL();

  url = `${url}${'/'+id}`;

  dispatch({
    
    type: GET_ECR_REPORT_LOADING,
  });

  let header = authHeaderWithToken();
  //let params = {reportid: id };
  
  let res = await get(url, header);
  if (res && res.status === 200) {
    //res && res.data

    return dispatch({
      type: GET_ECR_REPORT_SUCCESS,
      data: res.data, //res.data
    });
  } 
  else if(res && res.status === 204){
    warning('Content not available.')
    return dispatch({
      type: GET_ECR_REPORT_SUCCESS,
      data:res.data, 
    });
  }
  else if (res && res.isAxiosError) {
    if (res.response && res.response.data && res.response.data.message) {
      error(res.response.data.message);
    } else {
      error("Error while fetching report...");
    }
    return dispatch({
      type: GET_ECR_REPORT_ERROR,
      //payload: res.isAxiosError
    });
  }
}

export const CreateOrUpdateECRStage1 = (payload, isNew) => async (dispatch) => {
  let url = UrlConfig.createOrUpdateECRStage1URL();
  // url = `${url}${id}`;

  dispatch({
    type: ECR_INITIATED_LOADING,
  });

  let header = authHeaderWithToken();
  let res = await post(url, payload, header);
  if (res && res.status === 200) {
    //res && res.data
    let message = `ECR ${isNew ? 'Initiated submited' : 'updated'} successfully`;
    success(message);
    dispatch({
      type: ECR_INITIATED_SUCCESS,
      data: res.data,
    });
    return true;
  } else if (res && res.isAxiosError) {
    if (res.response && res.response.data && res.response.data.message) {
      error(res.response.data.message);
    } else {
      error("Error while initiating ECR...");
    }
    dispatch({
      type: ECR_INITIATED_ERROR,
      //payload: res.isAxiosError
    });
    return false;
  }
  return true;
};

// export const getCapaRport = (id) => async (dispatch) => {
//   let url = UrlConfig.getCAPAReportURL();
//   url = `${url}${'/'+id}`;

//   dispatch({
    
//     type: CAPA_REPORTS_LOADING,
//   });

//   let header = authHeaderWithToken();
//   //let params = {reportid: id };
  
//   let res = await get(url, header);
//   if (res && res.status === 200) {
//     //res && res.data

//     return dispatch({
//       type: CAPA_REPORT_SUCCESS,
//       data: res.data, //res.data
//     });
//   } 
//   else if(res && res.status === 204){
//     warning('Content not available.')
//     return dispatch({
//       type: CAPA_REPORT_SUCCESS,
//       data:res.data, 
//     });
//   }
//   else if (res && res.isAxiosError) {
//     if (res.response && res.response.data && res.response.data.message) {
//       error(res.response.data.message);
//     } else {
//       error("Error while fetching report...");
//     }
//     return dispatch({
//       type: CAPA_REPORT_ERROR,
//       //payload: res.isAxiosError
//     });
//   }
// }



// export const clearStoredReport = () => dispatch =>{
//   return dispatch({
//     type: NCR_CLEAR_REPORT
//   });
// }
