import {
    PTAB_COMPLETED_LOADING,
    PTAB_COMPLETED_SUCCESS,
    PTAB_COMPLETED_ERROR  
    
    } from "../../actions/PTAB/PTABInitiateAction";
    
    const initialState = {
      isSubmitLoading: false
    };
    
    const userReducer = function(state = initialState, action) {
    
      switch (action.type) {
        case PTAB_COMPLETED_LOADING: {
          return {
            ...state,
            isSubmitLoading: true
          };
        }
    
        case PTAB_COMPLETED_SUCCESS: {
          return {
            ...state,
            ptabReportId: action.data,
            isSubmitLoading: false
          };
        }
    
        case PTAB_COMPLETED_ERROR: {
          return {
            ...state,
            isSubmitLoading: false
          };
        }
        default: {
          return state;
        }
      }
    };
    
    export default userReducer;
    