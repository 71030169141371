import {
  NCR_DISP_ERROR, 
  NCR_DISP_SUCCESS, 
  NCR_DISP_LOADING
} from "../../actions/NCR/InitiationAction";

const initialState = {
  isNCRDispSubmitLoading: false
};

const userReducer = function(state = initialState, action) {
  switch (action.type) {
    case NCR_DISP_LOADING: {
      return {
        ...state,
        isNCRDispSubmitLoading: true
      };
    }

    case NCR_DISP_SUCCESS: {
      return {
        ...state,
        isNCRDispSubmitLoading: false
      };
    }

    case NCR_DISP_ERROR: {
      return {
        ...state,
        isNCRDispSubmitLoading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default userReducer;
