// import history from "history.js";
import axios from "axios";
import { get, post, getWithParams } from "../baseApi";
import { error, success, warning } from "../../../views/utilities/Toast/Toast";
import { authHeaderWithToken, authHeaderWithOutToken } from "../AxiosHeader";
import UrlConfig from "./UrlConfig";

export const GET_INITIAL_DATA_LOADING = "GET_INITIAL_DATA_LOADING";
export const GET_INITIAL_DATA_SUCCESS = "GET_INITIAL_DATA_SUCCESS";
export const GET_INITIAL_DATA_ERROR = "GET_INITIAL_DATA_ERROR";

export const NCR_INITIATE_LOADING = "NCR_INITIATE_LOADING";
export const NCR_INITIATE_SUCCESS = "NCR_INITIATE_SUCCESS";
export const NCR_INITIATE_ERROR = "NCR_INITIATE_ERROR";

export const NCR_DISP_LOADING = "NCR_DISP_LOADING";
export const NCR_DISP_SUCCESS = "NCR_DISP_SUCCESS";
export const NCR_DISP_ERROR = "NCR_DISP_ERROR";

export const NCR_REPORT_LOADING = "NCR_REPORT_LOADING";
export const NCR_REPORT_SUCCESS = "NCR_REPORT_SUCCESS";
export const NCR_REPORT_ERROR = "NCR_REPORT_ERROR";
export const NCR_CLEAR_REPORT = "NCR_CLEAR_REPORT";

export const getInitialData = () => async (dispatch) => {
  let areaofIdentificationCodeURL = UrlConfig.areaofIdentificationCodeURL();
  let defectCodeURL = UrlConfig.defectCodeURL();
  let causeCodeURL = UrlConfig.causeCodeURL();
  let dispositionCodeURL = UrlConfig.dispositionCodeURL();

  dispatch({
    type: GET_INITIAL_DATA_LOADING,
  });

  let header = authHeaderWithToken();
  let res = {};

  axios
    .all([
      axios.get(areaofIdentificationCodeURL, { headers: header }),
      axios.get(defectCodeURL, { headers: header }),
      axios.get(causeCodeURL, { headers: header }),
      axios.get(dispositionCodeURL, { headers: header }),
    ])
    .then((resp) => {
      console.log("area: ", resp[0].data);
      console.log("defectCode: ", resp[1].data);
      console.log("causeCode: ", resp[2].data);
      console.log("disposition: ", resp[3].data);
      res = {
        ...res,
        area: resp[0].data,
        defectCode: resp[1].data,
        causeCode: resp[2].data,
        disposition: resp[3].data,
      };

      dispatch({
        type: GET_INITIAL_DATA_SUCCESS,
        data: res,
      });
    })
    .catch((err) => {
      console.error("Error from POST call of ");
      dispatch({
        type: GET_INITIAL_DATA_ERROR,
      });
    });
};

export const addOrUpdateNCRInitiate = (payload, isNew) => async (dispatch) => {
  let url = UrlConfig.addOrUpdateNCRInitiateURL();
  // url = `${url}${id}`;

  dispatch({
    type: NCR_INITIATE_LOADING,
  });

  let header = authHeaderWithToken();
  let res = await post(url, payload, header);
  if (res && res.status === 200) {
    //res && res.data
    let message = `NCR ${isNew ? 'initiated' : 'updated'} successfully`;
    success(message);
    dispatch({
      type: NCR_INITIATE_SUCCESS,
      data: res.data,
    });
    return true;
  } else if (res && res.isAxiosError) {
    if (res.response && res.response.data && res.response.data.message) {
      error(res.response.data.message);
    } else {
      error("Error while initiating NCR...");
    }
    dispatch({
      type: NCR_INITIATE_ERROR,
      //payload: res.isAxiosError
    });
    return false;
  }
};

export const getNCRRport = (id) => async (dispatch) => {
  let url = UrlConfig.getNCRRportURL();
  url = `${url}${id}`;

  dispatch({
    type: NCR_REPORT_LOADING,
  });

  let header = authHeaderWithToken();
  //let params = {reportid: id };
  
  let res = await get(url, header);
  if (res && res.status === 200) {
    //res && res.data

    return dispatch({
      type: NCR_REPORT_SUCCESS,
      data: res.data, //res.data
    });
  } 
  else if(res && res.status === 204){
    warning('Content not available.')
    return dispatch({
      type: NCR_REPORT_SUCCESS
    });
  }
  else if (res && res.isAxiosError) {
    if (res.response && res.response.data && res.response.data.message) {
      error(res.response.data.message);
    } else {
      error("Error while fetching report...");
    }
    return dispatch({
      type: NCR_REPORT_ERROR,
      //payload: res.isAxiosError
    });
  }
}

export const clearStoredReport = () => dispatch =>{
  return dispatch({
    type: NCR_CLEAR_REPORT
  });
}
