import history from "history.js";
import { get, post } from "../baseApi";
import { error, success, warning } from "../../../views/utilities/Toast/Toast";
import { authHeaderWithToken } from "../AxiosHeader";
import UrlConfig from "./UrlConfig";
import { BottomNavigationAction } from "@material-ui/core";

export const CREATE_CONTAINER_LOADING = "CREATE_CONTAINER_LOADING";
export const CREATE_CONTAINER_SUCCESS = "CREATE_CONTAINER_SUCCESS";
export const CREATE_CONTAINER_ERROR = "CREATE_CONTAINER_ERROR";

export const CREATE_VALUE_LOADING = "CREATE_VALUE_LOADING";
export const CREATE_VALUE_SUCCESS = "CREATE_VALUE_SUCCESS";
export const CREATE_VALUE_ERROR = "CREATE_VALUE_ERROR";

export const CREATE_QTY_LOADING = "CREATE_QTY_LOADING";
export const CREATE_QTY_SUCCESS = "CREATE_QTY_SUCCESS";
export const CREATE_QTY_ERROR = "CREATE_QTY_ERROR";

export const GET_INIT_LOADING = "GET_INIT_LOADING";
export const GET_INIT_SUCCESS = "GET_INIT_SUCCESS";
export const GET_INIT_ERROR = "GET_INIT_ERROR";

export const GET_CONT_LOADING = "GET_CONT_LOADING";
export const GET_CONT_SUCCESS = "GET_CONT_SUCCESS";
export const GET_CONT_ERROR = "GET_CONT_ERROR";
export const CONT_CLEAR = "CONT_CLEAR";

export const GET_VALUE_LOADING = "GET_VALUE_LOADING";
export const GET_VALUE_SUCCESS = "GET_VALUE_SUCCESS";
export const GET_VALUE_ERROR = "GET_VALUE_ERROR";
export const VALUE_CLEAR = "CONT_CLEAR";

export const GET_QTY_LOADING = "GET_QTY_LOADING";
export const GET_QTY_SUCCESS = "GET_QTY_SUCCESS";
export const GET_QTY_ERROR = "GET_QTY_ERROR";
export const QTY_CLEAR = "QTY_CLEAR";

// GET_QTY_LOADING
// GET_QTY_SUCCESS
// GET_QTY_ERROR
// QTY_CLEAR

export const getFinancialYear = () => async (dispatch) => {
  let url = UrlConfig.getFinancialYearURL();
  dispatch({
    type: GET_INIT_LOADING,
  });

  let header = authHeaderWithToken();
  let res = await get(url, header);
  if (res && res.status === 200) {
    console.log('fy:', res.data);
    dispatch({
      type: GET_INIT_SUCCESS,
      data: res.data
    });

  } else if (res && res.isAxiosError) {
    if (res.response && res.response.data && res.response.data.message) {
      error(res.response.data.message);
    } else {
      error("Error while getting initial data...");
    }
    dispatch({
      type: GET_INIT_ERROR
    });
  }
};

//------------------updat containr planer------------

export const updateContainerPlanner = (payload) => async (dispatch) => {
  let url = UrlConfig.updateContainerPlannerURL();

  dispatch({
    type: CREATE_CONTAINER_LOADING,
  });

  let header = authHeaderWithToken();
  let res = await post(url, payload, header);
  if (res && res.status === 200) {
    //res && res.data
    success("Container added successfully...");

    dispatch({
      type: CREATE_CONTAINER_SUCCESS,
    });
    return true;

  } else if (res && res.isAxiosError) {
    if (res.response && res.response.data && res.response.data.message) {
      error(res.response.data.message);
    } else {
      error("Error while updating Container...");
    }
    dispatch({
      type: CREATE_CONTAINER_ERROR,
      //payload: res.isAxiosError
    });
    return false;
  }
};


//-----------------get container planner----------

export const getContainerPlanner = (month, year) => async (dispatch) => {
  let url = UrlConfig.getContDataURL();
  url = `${url}${month}/${year}`;

  dispatch({
    type: GET_CONT_LOADING,
  });

  let header = authHeaderWithToken();
  let res = await get(url, header);
  if (res && res.status === 200) {
    console.log('exst:', res.data);
    dispatch({
      type: GET_CONT_SUCCESS,
      data: res.data
    });
    return res.data;

  }

  else if (res && res.status === 204) {
    warning('No content to display..');
    return dispatch({
      type: GET_CONT_SUCCESS,
      data: res.data
    });
  } 
  else if (res && res.isAxiosError) {
    if (res.response && res.response.data && res.response.data.message) {
      error(res.response.data.message);
    } else {
      error("Error while getting data...");
    }
    dispatch({
      type: GET_CONT_ERROR
    });
    return null;
  }
};


export const clearStoredData = () => dispatch =>{
  return dispatch({
    type: CONT_CLEAR
  });
}

//-----------------update value-------------------------------------------------

export const updateValuePlanner = (payload) => async (dispatch) => {
  let url = UrlConfig.updateValuePlannerURL();

  dispatch({
    type: CREATE_VALUE_LOADING,
  });

  let header = authHeaderWithToken();
  let res = await post(url, payload, header);
  if (res && res.status === 200) {
    //res && res.data
    success("Value added successfully...");

    dispatch({
      type: CREATE_VALUE_SUCCESS,
    });
    return true;
  } else if (res && res.isAxiosError) {
    if (res.response && res.response.data && res.response.data.message) {
      error(res.response.data.message);
    } else {
      error("Error while updating Value...");
    }
    dispatch({
      type: CREATE_VALUE_ERROR,
      //payload: res.isAxiosError
    });
    return false;
  }
};

//-----------------get value planner----------

export const getValuePlanner = (month, year) => async (dispatch) => {
  let url = UrlConfig.getValueDataURL();
  url = `${url}${month}/${year}`;

  dispatch({
    type: GET_VALUE_LOADING,
  });

  let header = authHeaderWithToken();
  let res = await get(url, header);
  if (res && res.status === 200) {
    console.log('exstQTY:', res.data);
    dispatch({
      type: GET_VALUE_SUCCESS,
      data: res.data
    });
    return res.data;

  } 
  else if (res && res.status === 204) {
    warning('No content to display..');
    return dispatch({
      type: GET_VALUE_SUCCESS,
      data: res.data
    });
  } 
  else if (res && res.isAxiosError) {
    if (res.response && res.response.data && res.response.data.message) {
      error(res.response.data.message);
    } else {
      error("Error while getting data...");
    }
    dispatch({
      type: GET_VALUE_ERROR
    });
    return null;
  }
};


export const clearStoredValueData = () => dispatch =>{
  return dispatch({
    type: VALUE_CLEAR
  });
}

//-----------------QTY---------------------------------
export const updateQTYPlanner = (payload) => async (dispatch) => {
  let url = UrlConfig.updateQTYPlannerURL();

  dispatch({
    type: CREATE_QTY_LOADING,
  });

  let header = authHeaderWithToken();
  let res = await post(url, payload, header);
  if (res && res.status === 200) {
    //res && res.data
    success("Quantity updated successfully...");

    dispatch({
      type: CREATE_QTY_SUCCESS,
    });
    return true;
  } else if (res && res.isAxiosError) {
    if (res.response && res.response.data && res.response.data.message) {
      error(res.response.data.message);
    } else {
      error("Error while updating Quantity...");
    }
    dispatch({
      type: CREATE_QTY_ERROR,
      //payload: res.isAxiosError
    });
    return false;
  }
};

//-----------------get QTY planner----------

export const getQTYPlanner = (month, year) => async (dispatch) => {
  let url = UrlConfig.getQTYDataURL();
  url = `${url}${month}/${year}`;

  dispatch({
    type: GET_QTY_LOADING,
  });

  let header = authHeaderWithToken();
  let res = await get(url, header);
  if (res && res.status === 200) {
    console.log('exstQTY:', res.data);
    dispatch({
      type: GET_QTY_SUCCESS,
      data: res.data
    });
    return res.data;

  }
  else if (res && res.status === 204) {
    warning('No content to display..');
    return dispatch({
      type: GET_QTY_SUCCESS,
      data: res.data
    });
  } 
  else if (res && res.isAxiosError) {
    if (res.response && res.response.data && res.response.data.message) {
      error(res.response.data.message);
    } else {
      error("Error while getting data...");
    }
    dispatch({
      type: GET_QTY_ERROR
    });
    return null;
  }
};


export const clearStoredQTYData = () => dispatch =>{
  return dispatch({
    type: QTY_CLEAR
  });
}