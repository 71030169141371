// import history from "history.js";
import axios from "axios";
import { get, post, getWithParams } from "../baseApi";
import { error, success, warning } from "../../../views/utilities/Toast/Toast";
import { authHeaderWithToken, authHeaderWithOutToken } from "../AxiosHeader";
import UrlConfig from "./UrlConfig";

export const PTAB_INITIATE_LOADING = "PTAB_INITIATE_LOADING"; 
export const PTAB_INITIATE_SUCCESS = "PTAB_INITIATE_SUCCESS";
export const PTAB_INITIATE_ERROR  =  "PTAB_INITIATE_ERROR";

export const PTAB_COMPLETED_LOADING = "PTAB_COMPLETED_LOADING"; 
export const PTAB_COMPLETED_SUCCESS = "PTAB_COMPLETED_SUCCESS";
export const PTAB_COMPLETED_ERROR  =  "PTAB_COMPLETED_ERROR";

export const GET_PTAB_REPORT_LOADING = "GET_PTAB_REPORT_LOADING";
export const GET_PTAB_REPORT_SUCCESS = "GET_PTAB_REPORT_SUCCESS";
export const GET_PTAB_REPORT_ERROR  =  "GET_PTAB_REPORT_ERROR"; 

export const CreateOrUpdatePTABStage1 = (payload, isNew) => async (dispatch) => {
  let url = UrlConfig.ptabInitiateURL();

  dispatch({
    type: PTAB_INITIATE_LOADING,
  });

  let header = authHeaderWithToken();
  let res = await post(url, payload, header);
  if (res && res.status === 200) {
    let message = `PTAB ${isNew ? 'Initiated submited' : 'updated'} successfully`;
    success(message);
    dispatch({
      type: PTAB_INITIATE_SUCCESS,
      data: res.data,
    });
    return true;
  } else if (res && res.isAxiosError) {
    if (res.response && res.response.data && res.response.data.message) {
      error(res.response.data.message);
    } else {
      error("Error while initiating PTAB...");
    }
    dispatch({
      type: PTAB_INITIATE_ERROR,
      //payload: res.isAxiosError
    });
    return false;
  }
  return true;
};

export const CreateOrUpdatePTABStage2 = (payload, isNew) => async (dispatch) => {
  let url = UrlConfig.ptabCompletedURL();
  // url = `${url}${id}`;

  dispatch({
    type: PTAB_COMPLETED_LOADING,
  });

  let header = authHeaderWithToken();
  let res = await post(url, payload, header);
  if (res && res.status === 200) {
    let message = `PTAB ${isNew ? 'Initiated submited' : 'updated'} successfully`;
    success(message);
    dispatch({
      type: PTAB_COMPLETED_SUCCESS,
      data: res.data,
    });
    return true;
  } else if (res && res.isAxiosError) {
    if (res.response && res.response.data && res.response.data.message) {
      error(res.response.data.message);
    } else {
      error("Error while initiating PTAB...");
    }
    dispatch({
      type: PTAB_COMPLETED_ERROR,
      //payload: res.isAxiosError
    });
    return false;
  }
  return true;
};

//GET PTAB REPORT

export const getPTabReport = (id) => async (dispatch) => {
  let url = UrlConfig.getPtabReportURL();

  url = `${url}${'/'+id}`;

  dispatch({
    
    type: GET_PTAB_REPORT_LOADING,
  });

  let header = authHeaderWithToken();
  //let params = {reportid: id };
  
  let res = await get(url, header);
  if (res && res.status === 200) {
    //res && res.data
    return dispatch({
      type: GET_PTAB_REPORT_SUCCESS,
      data: res.data, //res.data
    });
  } 
  else if(res && res.status === 204){
    warning('Content not available.')
    return dispatch({
      type: GET_PTAB_REPORT_SUCCESS,
      data:res.data, 
    });
  }
  else if (res && res.isAxiosError) {
    if (res.response && res.response.data && res.response.data.message) {
      error(res.response.data.message);
    } else {
      error("Error while fetching report...");
    }
    return dispatch({
      type: GET_PTAB_REPORT_ERROR,
      //payload: res.isAxiosError
    });
  }
}



