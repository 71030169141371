import {
  NCR_ACCEP_ERROR, 
  NCR_ACCEP_SUCCESS, 
  NCR_ACCEP_LOADING
} from "../../actions/NCR/AcceptanceAction";

const initialState = {
  isNCRAcceptSubmitLoading: false
};

const userReducer = function(state = initialState, action) {
  switch (action.type) {
    case NCR_ACCEP_LOADING: {
      return {
        ...state,
        isNCRAcceptSubmitLoading: true
      };
    }

    case NCR_ACCEP_SUCCESS: {
      return {
        ...state,
        isNCRAcceptSubmitLoading: false
      };
    }

    case NCR_ACCEP_ERROR: {
      return {
        ...state,
        isNCRAcceptSubmitLoading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default userReducer;
