import {
  CREATE_ORDER_LOADING,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_ERROR,
  GET_ORDER_LOADING,
  GET_ORDER_SUCCESS,
  GET_ORDER_ERROR,
  SELECTED_ORDER,
  CLEAR_SELECTED_ORDER
} from "../../../actions/PPC/OrderAction/OrderAction";

const initialState = {
  isCreateOrderLoading: false,
  isGetOrderLoading: false,
  orders: [],
  selectedOrder: null
};

const orderReducer = function (state = initialState, action) {
  switch (action.type) {
    case CREATE_ORDER_LOADING: {
      return {
        ...state,
        isCreateOrderLoading: true,
      };
    }
    case CREATE_ORDER_SUCCESS: {
      return {
        ...state,
        isCreateOrderLoading: false,
      };
    }
    case CREATE_ORDER_ERROR: {
      return {
        ...state,
        isCreateOrderLoading: false,
      };
    }

    case GET_ORDER_LOADING: {
      return {
        ...state,
        isGetOrderLoading: true,
      };
    }
    case GET_ORDER_SUCCESS: {
      return {
        ...state,
        orders: action.payload,
        isGetOrderLoading: false,
      };
    }
    case GET_ORDER_ERROR: {
      return {
        ...state,
        isGetOrderLoading: false,
      };
    }

    case SELECTED_ORDER: {
      return {
        ...state,
        selectedOrder: action.payload
      }
    }

    case CLEAR_SELECTED_ORDER: {
      return {
        ...state,
        selectedOrder: null
      }
    }

    default: {
      return state;
    }
  }
};

export default orderReducer;
