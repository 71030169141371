import {
  CREATE_PRODUCT_LOADING,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_ERROR,
  GET_PRODUCT_LOADING,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_ERROR,
  GET_SUB_PRODUCT_LOADING,
  GET_SUB_PRODUCT_SUCCESS,
  GET_SUB_PRODUCT_ERROR,
  CLEAR_SELECTED_PROD,
  GET_ASSIGNED_SUB_PRODUCT_LOADING,
  GET_ASSIGNED_SUB_PRODUCT_SUCCESS,
  GET_ASSIGNED_SUB_PRODUCT_ERROR,
  GET_ASSIGNED_PRODUCT_SUCCESS,

  
UPDATE_ASSG_SUB_PRODUCT_LOADING,
UPDATE_ASSG_SUB_PRODUCT_SUCCESS,
UPDATE_ASSG_SUB_PRODUCT_ERROR,

UPDATE_ASSG_PRODUCT_LOADING,
UPDATE_ASSG_PRODUCT_SUCCESS,
UPDATE_ASSG_PRODUCT_ERROR
  // STORE_SELECTED_PROD,
  // CLEAR_SELECTED_PROD
} from "../../../actions/PPC/ProductsAction/ProductsAction";

const initialState = {
  isCreateProductLoading: false,
  isGetProductLoading: false,
  products: [],
  isGetSubProdLoading: false,
  subProducts: [],
  subUsers: [],
  isGetAssignedSubProdLoading: false,
  assignedSubProducts: [],
  assignedProducts: [],
  isupdtAsgnSubProdLoading: false,
  isupdtAsgnProdLoading: false,

  // selectedProd: null
};

const productReducer = function (state = initialState, action) {
  switch (action.type) {
    case CREATE_PRODUCT_LOADING: {
      return {
        ...state,
        isCreateProductLoading: true,
      };
    }
    case CREATE_PRODUCT_SUCCESS: {
      return {
        ...state,
        isCreateProductLoading: false,
      };
    }
    case CREATE_PRODUCT_ERROR: {
      return {
        ...state,
        isCreateProductLoading: false,
      };
    }

    case GET_PRODUCT_LOADING: {
      return {
        ...state,
        isGetProductLoading: true,
      };
    }
    case GET_PRODUCT_SUCCESS: {
      return {
        ...state,
        products: action.payload,
        isGetProductLoading: false,
      };
    }
    case GET_PRODUCT_ERROR: {
      return {
        ...state,
        isGetProductLoading: false,
      };
    }

    case GET_SUB_PRODUCT_LOADING: {
      return {
        ...state,
        isGetSubProdLoading: true,
      };
    }
    case GET_SUB_PRODUCT_SUCCESS: {
      return {
        ...state,
        subProducts: action.payload.subProducts,
        subUsers: action.payload.subUsers,
        isGetSubProdLoading: false,
      };
    }
    case GET_SUB_PRODUCT_ERROR: {
      return {
        ...state,
        isGetSubProdLoading: false,
      };
    }

    case CLEAR_SELECTED_PROD: {
      return {
        ...state,
        products: [],
        isGetProductLoading: false,
      };
    }




    case GET_ASSIGNED_SUB_PRODUCT_LOADING: {
      return {
        ...state,
        isGetAssignedSubProdLoading: true,
      };
    }
    case GET_ASSIGNED_SUB_PRODUCT_SUCCESS: {
      return {
        ...state,
        assignedSubProducts: action.payload,
        isGetAssignedSubProdLoading: false,
      };
    }
    case GET_ASSIGNED_SUB_PRODUCT_ERROR: {
      return {
        ...state,
        isGetAssignedSubProdLoading: false,
      };
    }

    case GET_ASSIGNED_PRODUCT_SUCCESS: {
      return {
        ...state,
        assignedProducts: action.payload,
        isGetAssignedSubProdLoading: false,
      };
    }




    case UPDATE_ASSG_SUB_PRODUCT_LOADING: {
      return {
        ...state,
        isupdtAsgnSubProdLoading: true,
      };
    }
    case UPDATE_ASSG_SUB_PRODUCT_SUCCESS: {
      return {
        ...state,
        isupdtAsgnSubProdLoading: false,
      };
    }
    case UPDATE_ASSG_SUB_PRODUCT_ERROR: {
      return {
        ...state,
        isupdtAsgnSubProdLoading: false,
      };
    }






    case UPDATE_ASSG_PRODUCT_LOADING: {
      return {
        ...state,
        isupdtAsgnProdLoading: true,
      };
    }
    case UPDATE_ASSG_PRODUCT_SUCCESS: {
      return {
        ...state,
        isupdtAsgnProdLoading: false,
      };
    }
    case UPDATE_ASSG_PRODUCT_ERROR: {
      return {
        ...state,
        isupdtAsgnProdLoading: false,
      };
    }


    // case STORE_SELECTED_PROD: {
    //   return {
    //     ...state,
    //     selectedProd: false,
    //   };
    // }

    // case CLEAR_SELECTED_PROD: {
    //   return {
    //     ...state,
    //     selectedProd: null,
    //   };
    // }

    default: {
      return state;
    }
  }
};

export default productReducer;
