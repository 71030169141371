import {
  CAPA_REPORT_ERROR, 
  CAPA_REPORT_SUCCESS, 
  CAPA_REPORT_LOADING
} from "../../actions/CAPA/CapaReportAction";

const initialState = {
  isSubmitLoading: false
};

const userReducer = function(state = initialState, action) {
  switch (action.type) {
    case CAPA_REPORT_LOADING: {
      return {
        ...state,
        isSubmitCapaReportLoading: true
      };
    }

    case CAPA_REPORT_SUCCESS: {
      return {
        ...state,
        isSubmitCapaReportLoading: false
      };
    }

    case CAPA_REPORT_ERROR: {
      return {
        ...state,
        isSubmitCapaReportLoading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default userReducer;
