class UrlConfig {
    areaofIdentificationCodeURL (){
        return "http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/NCR/GetAreaofIdentificationCode";
    }

    defectCodeURL (){
        return "http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/NCR/GetDefectCode";
    }

    causeCodeURL(){
        return 'http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/NCR/GetCauseCode'
    }

    dispositionCodeURL(){
        return 'http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/NCR/GetDispositionCode'
    }

    addOrUpdateNCRInitiateURL(){
        return 'http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/NCR/CreateOrUpdateNCRInitiate'
    }

    createOrUpdateNCRDispositionURL(){
        return 'http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/NCR/CreateOrUpdateNCRDisposition'
    }

    createOrUpdateNCRAcceptanceURL(){
        return 'http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/NCR/CreateOrUpdateNCRAcceptance'
    }

    getNCRRportURL(){
        return 'http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/NCR/GetNCRReport/'
    }
}

export default new UrlConfig();