import { get, post } from "../baseApi";
import { error, success } from "../../../views/utilities/Toast/Toast";
import { authHeaderWithToken } from "../AxiosHeader";
import UrlConfig from "./UrlConfig";

export const GET_TICKET_LOADING = "GET_TICKET_LOADING";
export const GET_TICKET_SUCCESS = "GET_TICKET_SUCCESS";
export const GET_TICKET_ERROR = "GET_TICKET_ERROR";

export const SUBMIT_TICKET_ERROR = "SUBMIT_TICKET_ERROR";
export const SUBMIT_TICKET_SUCCESS = "SUBMIT_TICKET_SUCCESS";
export const SUBMIT_TICKET_LOADING = "SUBMIT_TICKET_LOADING";


export const submitTicket = (payload) => async (dispatch) => {
  let url = UrlConfig.submitTicketURL();

  dispatch({
    type: SUBMIT_TICKET_LOADING,
  });

  let header = authHeaderWithToken();
  let res = await post(url, payload, header);
  if (res && res.status === 200) {
    success("Ticket Submitted successfully...");
    dispatch({
      type: SUBMIT_TICKET_SUCCESS
    });
    return true;

  } else if (res && res.isAxiosError) {
    if (res.response && res.response.data && res.response.data.message) {
      error(res.response.data.message);
    } else {
      error("Error while Submit ticket..");
    }
    dispatch({
      type: SUBMIT_TICKET_ERROR
    });
    return false;
  }
};

export const getTicketType = () => async (dispatch) => {
  let url = UrlConfig.getTicketTypeURL();

  dispatch({
    type: GET_TICKET_LOADING,
  });

  let header = authHeaderWithToken();
  
  let res = await get(url, header);
  if (res && res.status === 200) {
    //res && res.data

    return dispatch({
      type: GET_TICKET_SUCCESS,
      payload: res.data,
    });
  } else if (res && res.isAxiosError) {
    if (res.response && res.response.data && res.response.data.message) {
      error(res.response.data.message);
    } else {
      error("Error while fetching data...");
    }
    return dispatch({
      type: GET_TICKET_ERROR
    });
  }
};

