import {
CAPA_ROOTCAUSE_LOADING,
CAPA_ROOTCAUSE_SUCCESS ,

CAPA_ROOTCAUSE_ERROR,

CAPA_REPORTS_LOADING,
CAPA_REPORT_SUCCESS,
CAPA_REPORT_ERROR

} from "../../actions/CAPA/CapaRootCauseAction";

const initialState = {
  isSubmitLoading: false
};

const userReducer = function(state = initialState, action) {

  switch (action.type) {
    case CAPA_ROOTCAUSE_LOADING: {
      return {
        ...state,
        isSubmitLoading: true
      };
    }

    case CAPA_ROOTCAUSE_SUCCESS: {
      return {
        ...state,
        isSubmitLoading: false
      };
    }

    case CAPA_ROOTCAUSE_ERROR: {
      return {
        ...state,
        isSubmitLoading: false
      };
    }

    case CAPA_REPORTS_LOADING: {
      return {
        ...state,
        isCapaReportLoading: true
      };
    }

    case CAPA_REPORT_SUCCESS: {
      return {
        ...state,
        report: action.data,
        isCapaReportLoading: false
      };
    }

    case CAPA_REPORT_ERROR: {
      return {
        ...state,
        isCapaReportLoading: false
      };
    }

    // case NCR_CLEAR_REPORT: {
    //   return {
    //     ...state,
    //     report: null
    //   };
    // }

    default: {
      return state;
    }
  }
};

export default userReducer;
