import {
  GET_INITIAL_DATA_LOADING,
  GET_INITIAL_DATA_SUCCESS,
  GET_INITIAL_DATA_ERROR,
  NCR_INITIATE_LOADING,
  NCR_INITIATE_SUCCESS,
  NCR_INITIATE_ERROR,
  NCR_REPORT_LOADING,
  NCR_REPORT_SUCCESS,
  NCR_REPORT_ERROR,
  NCR_CLEAR_REPORT
} from "../../actions/NCR/InitiationAction";

const initialState = {
  loadingInitData: false,
  initialData: null,
  isNCRSubmitLoading: false,
  isNCRReportLoading: false,
  report: null,
  reportNumber: ''
};

const userReducer = function(state = initialState, action) {
  switch (action.type) {
    case GET_INITIAL_DATA_LOADING: {
      return {
        ...state,
        loadingInitData: true
      };
    }
    case GET_INITIAL_DATA_SUCCESS: {
      return {
        ...state, 
        initialData: action.data,
        loadingInitData: false
      };
    }
    case GET_INITIAL_DATA_ERROR: {
      return {
        ...state,
        loadingInitData: false
      };
    }

    case NCR_INITIATE_LOADING: {
      return {
        ...state,
        isNCRSubmitLoading: true
      };
    }

    case NCR_INITIATE_SUCCESS: {
      return {
        ...state,
        reportNumber: action.data,
        isNCRSubmitLoading: false
      };
    }

    case NCR_INITIATE_ERROR: {
      return {
        ...state,
        isNCRSubmitLoading: false
      };
    }

    case NCR_REPORT_LOADING: {
      return {
        ...state,
        isNCRReportLoading: true
      };
    }

    case NCR_REPORT_SUCCESS: {
      return {
        ...state,
        report: action.data,
        isNCRReportLoading: false
      };
    }

    case NCR_REPORT_ERROR: {
      return {
        ...state,
        isNCRReportLoading: false
      };
    }

    case NCR_CLEAR_REPORT: {
      return {
        ...state,
        report: null
      };
    }

    default: {
      return state;
    }
  }
};

export default userReducer;
