// import history from "history.js";
import axios from "axios";
import { get, post, getWithParams } from "../baseApi";
import { error, success, warning } from "../../../views/utilities/Toast/Toast";
import { authHeaderWithToken, authHeaderWithOutToken } from "../AxiosHeader";
import UrlConfig from "./UrlConfig";

export const ECR_QUANTITY_LOADING = "ECR_QUANTITY_LOADING"; 
export const ECR_QUANTITY_SUCCESS = "ECR_QUANTITY_SUCCESS";
export const ECR_QUANTITY_ERROR  =  "ECR_QUANTITY_ERROR";


export const CreateOrUpdateECRStage3 = (payload, isNew) => async (dispatch) => {
    let url = UrlConfig.createOrUpdateECRStage3URL();
    // url = `${url}${id}`;
  
    dispatch({
      type: ECR_QUANTITY_LOADING,
    });
  
    let header = authHeaderWithToken();
    let res = await post(url, payload, header);
    if (res && res.status === 200) {
      //res && res.data
      let message = `ECR ${isNew ? 'Initiated submited' : 'updated'} successfully`;
      success(message);
      dispatch({
        type: ECR_QUANTITY_SUCCESS,
        data: res.data,
      });
      return true;
    } else if (res && res.isAxiosError) {
      if (res.response && res.response.data && res.response.data.message) {
        error(res.response.data.message);
      } else {
        error("Error while initiating ECR...");
      }
      dispatch({
        type: ECR_QUANTITY_ERROR,
        //payload: res.isAxiosError
      });
      return false;
    }
    return true;
  };

// export const getInitialData = (id) => async (dispatch) => {
//   const groupId = id;//sessionStorage.getItem("UserGroupId");
//   let areaofIdentificationCodeURL = UrlConfig.areaofIdentificationCodeURL();
//   let defectCodeURL = UrlConfig.defectCodeURL();
//   let causeCodeURL = UrlConfig.causeCodeURL();
//   let dispositionCodeURL = UrlConfig.dispositionCodeURL();
//   let getCAPAReportNumbersURL=UrlConfig.getCAPAReportNumbers();
//   let getCAPAUserListURL=UrlConfig.getCAPAUserListURL();
//       getCAPAUserListURL=getCAPAUserListURL+groupId;

//   dispatch({
//     type: GET_INITIAL_DATA_LOADING,
//   });

//   let header = authHeaderWithToken();
//   let res = {};
//   axios
//     .all([
//       axios.get(areaofIdentificationCodeURL, { headers: header }),
//       axios.get(defectCodeURL, { headers: header }),
//       axios.get(causeCodeURL, { headers: header }),
//       axios.get(dispositionCodeURL, { headers: header }),
//       axios.get(getCAPAReportNumbersURL, { headers: header }),
//       axios.get(getCAPAUserListURL, { headers: header }),

//     ])
//     .then((resp) => {
//       console.log("area: ", resp[0].data);
//       console.log("defectCode: ", resp[1].data);
//       console.log("causeCode: ", resp[2].data);
//       console.log("disposition: ", resp[3].data);
//       console.log("capareportnumbers: ", resp[4].data);
//       console.log("capaGroupUsers: ", resp[5].data);
      
//       res = {
//         ...res,
//         area: resp[0].data,
//         defectCode: resp[1].data,
//         causeCode: resp[2].data,
//         disposition: resp[3].data,
//         capareportnumbers:resp[4].data,
//         capaGroupUsers:resp[5].data,
//       };
//       dispatch({
//         type: GET_INITIAL_DATA_SUCCESS,
//         data: res,
//       });
//     })
//     .catch((err) => {
//       console.error("Error from POST call of ");
//       dispatch({
//         type: GET_INITIAL_DATA_ERROR,
//       });
//     });
// };



// export const getCapaRport = (id) => async (dispatch) => {
//   let url = UrlConfig.getCAPAReportURL();
//   url = `${url}${'/'+id}`;

//   dispatch({
    
//     type: CAPA_REPORTS_LOADING,
//   });

//   let header = authHeaderWithToken();
//   //let params = {reportid: id };
  
//   let res = await get(url, header);
//   if (res && res.status === 200) {
//     //res && res.data

//     return dispatch({
//       type: CAPA_REPORT_SUCCESS,
//       data: res.data, //res.data
//     });
//   } 
//   else if(res && res.status === 204){
//     warning('Content not available.')
//     return dispatch({
//       type: CAPA_REPORT_SUCCESS,
//       data:res.data, 
//     });
//   }
//   else if (res && res.isAxiosError) {
//     if (res.response && res.response.data && res.response.data.message) {
//       error(res.response.data.message);
//     } else {
//       error("Error while fetching report...");
//     }
//     return dispatch({
//       type: CAPA_REPORT_ERROR,
//       //payload: res.isAxiosError
//     });
//   }
// }



// export const clearStoredReport = () => dispatch =>{
//   return dispatch({
//     type: NCR_CLEAR_REPORT
//   });
// }
