import {
    ECR_QUANTITY_LOADING,
    ECR_QUANTITY_SUCCESS,
    ECR_QUANTITY_ERROR  ,
   
    
    } from "../../actions/ECR/ECRQualityAction";
    
    const initialState = {
      isSubmitLoading: false
    };
    
    const userReducer = function(state = initialState, action) {
    
      switch (action.type) {
        case ECR_QUANTITY_LOADING: {
          return {
            ...state,
            isSubmitLoading: true
          };
        }
    
        case ECR_QUANTITY_SUCCESS: {
          return {
            ...state,
            isSubmitLoading: false
          };
        }
    
        case ECR_QUANTITY_ERROR: {
          return {
            ...state,
            isSubmitLoading: false
          };
        }
        default: {
          return state;
        }
      }
    };
    
    export default userReducer;
    