

class UrlConfig {
    areaofIdentificationCodeURL (){
        return "http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/NCR/GetAreaofIdentificationCode";
    }

    defectCodeURL (){
        return "http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/NCR/GetDefectCode";
    }

    causeCodeURL(){
        return 'http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/NCR/GetCauseCode'
    }

    dispositionCodeURL(){
        return 'http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/NCR/GetDispositionCode'
    }

    getCAPAReportNumbers(){
        return 'http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/NCR/GetCAPAReportNumbers'
    }

    addOrUpdateCapaRootCauseURL(){
        return 'http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/CAPA/CreateOrUpdateCAPARootCauseAnalysis'
    }

    createOrUpdateCAPACorrectiveActionTaken(){
        return 'http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/CAPA/CreateOrUpdateCAPACorrectiveActionTaken'
    }

    createOrUpdateCAPAPreventiveActionTaken(){
        return 'http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/CAPA/CreateOrUpdateCAPAPreventiveActionTaken'
    }

    createOrUpdateCAPACorrectiveActionClosure(){
        return 'http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/CAPA/CreateOrUpdateCAPACorrectiveActionClosure'
    }

    createOrUpdateCAPAPreventiveActionClosure(){
        return 'http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/CAPA/CreateOrUpdateCAPAPreventiveActionClosure'
    }

    getCAPAReportURL(){
        return 'http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/CAPA/GetCAPAReport'
    }

    

    getCAPAUserListURL(){
        return 'http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/user/GetUsers/'
    }

} 

export default new UrlConfig();
