import {
  PDO_STAGE1_LOADING,
  PDO_STAGE1_SUCCESS,
  PDO_STAGE1_ERROR,

  PDO_REPORT_LOADING,
  PDO_REPORT_SUCCESS,
  PDO_REPORT_ERROR,
  PDO_CLEAR_REPORT,

  REF_LOADING,
  REF_SUCCESS,
  REF_ERROR

} from "../../actions/PDO/Stage1Action";

const initialState = {
  isPDOSubmitLoading: false,
  isPDOReportLoading: false,
  report: null,
  reportNumber: '',
  refNo: null,
  refLoading: false
};

const userReducer = function(state = initialState, action) {
  switch (action.type) {
   
    case PDO_STAGE1_LOADING: {
      return {
        ...state,
        isPDOSubmitLoading: true
      };
    }

    case PDO_STAGE1_SUCCESS: {
      return {
        ...state,
        reportNumber: action.data,
        isPDOSubmitLoading: false
      };
    }

    case PDO_STAGE1_ERROR: {
      return {
        ...state,
        isPDOSubmitLoading: false
      };
    }

    case PDO_REPORT_LOADING: {
      return {
        ...state,
        isPDOReportLoading: true
      };
    }

    case PDO_REPORT_SUCCESS: {
      return {
        ...state,
        report: action.data,
        isPDOReportLoading: false
      };
    }

    case PDO_REPORT_ERROR: {
      return {
        ...state,
        isPDOReportLoading: false
      };
    }

    case PDO_CLEAR_REPORT: {
      return {
        ...state,
        report: null
      };
    }

    case REF_LOADING: {
      return {
        ...state,
        refLoading: true
      };
    }

    case REF_SUCCESS: {
      return {
        ...state,
        refNo: action.data,
        refLoading: false
      };
    }

    case REF_ERROR: {
      return {
        ...state,
        refLoading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default userReducer;
