class UrlConfig {
    getDonutDataURL (){
        return "http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/Dashboard/GetPlannerForDoughNutChart";
    }

    getLineChartDataURL (){
        return "http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/Dashboard/GetPlannerForLineChart";
    }
}

export default new UrlConfig();