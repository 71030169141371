import {
  GET_TICKET_LOADING,
  GET_TICKET_SUCCESS,
  GET_TICKET_ERROR,
  SUBMIT_TICKET_ERROR,
  SUBMIT_TICKET_SUCCESS,
  SUBMIT_TICKET_LOADING,
} from "../../actions/Ticket/TicketAction";

const initialState = {
  initLoading: false,
  ticketTypes: [],
  loading: false,
};

const ticketReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_TICKET_LOADING: {
      return {
        ...state,
        initLoading: true,
      };
    }
    case GET_TICKET_SUCCESS: {
      return {
        ...state,
        ticketTypes: action.payload,
        initLoading: false,
      };
    }
    case GET_TICKET_ERROR: {
      return {
        ...state,
        initLoading: false,
      };
    }

    case SUBMIT_TICKET_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SUBMIT_TICKET_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case SUBMIT_TICKET_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default ticketReducer;
