import {
  PDO_STAGE3_LOADING, 
  PDO_STAGE3_SUCCESS, 
  PDO_STAGE3_ERROR
} from "../../actions/PDO/Stage3Action";

const initialState = {
  isPDOStage3SubmitLoading: false
};

const userReducer = function(state = initialState, action) {
  switch (action.type) {
    case PDO_STAGE3_LOADING: {
      return {
        ...state,
        isPDOStage3SubmitLoading: true
      };
    }

    case PDO_STAGE3_SUCCESS: {
      return {
        ...state,
        isPDOStage3SubmitLoading: false
      };
    }

    case PDO_STAGE3_ERROR: {
      return {
        ...state,
        isPDOStage3SubmitLoading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default userReducer;
